import { useTranslation } from "react-i18next";
import { HeaderDefinition } from "../../utils/bigTable/definition/HeaderDefinition";
import { UserOrder } from "../../../type/UserOrder";
import ContactElement from "../../utils/ContactElement";

export const HISTORY_ACTION_OWNER: string = "HISTORY_ACTION_OWNER";
export const HISTORY_CHANGE_DATE: string = "HISTORY_CHANGE_DATE";
export const HISTORY_COMMENT: string = "HISTORY_COMMENT";
export const HISTORY_STATUS: string = "STATUS";

export const OrderHistoryColumns = (
        aseclaAdmin: boolean
        , columnsToShowByDefault?: string[]): HeaderDefinition[] => {
    const { t } = useTranslation();

    let headers: HeaderDefinition[] = [];


    const defineTableHeader = (props: HeaderDefinition) => {
        if (columnsToShowByDefault != undefined) {
            if (columnsToShowByDefault.indexOf(props.headerKey) === -1) {
                props.display = false;
            }
        }
        headers.push(props);
    }

    const formatDate = (date: Date): string => {
        if (date == undefined) return "";
        let twoCharacters = (i: number): string => {
            if (i < 10) return "0" + i;
            return i + "";
        }
        return twoCharacters(date.getDate()) + "." + twoCharacters(date.getMonth()+1) + "." + date.getFullYear();
    }



    defineTableHeader({headerKey: HISTORY_ACTION_OWNER, display: true, alwaysHide: !aseclaAdmin, displayedHeader: t("Action owner"), getCellContent: ({object}) => <ContactElement user={object.actionOwner}></ContactElement>});
    defineTableHeader({headerKey: HISTORY_CHANGE_DATE, display: true, displayedHeader: t("Change date"), getCellContent: ({object: history}) => {return formatDate(history.changeDate)}});
    defineTableHeader({headerKey: HISTORY_COMMENT, display: true, displayedHeader: t("Comment"), getCellContent: ({object: history}) => {return history.comment}});
    defineTableHeader({headerKey: HISTORY_STATUS, display: true, displayedHeader: t("Status"), getCellContent: ({object}) => object.status});

    return headers;
}
