import React from "react";
import { ContextProps } from "../../type/ContextProps";
import { LicenseCode } from "../../type/LicenseCode";
import LicensesDetails from "./LicensesDetails"
import AseclaDataContext from "../../store/AseclaDataContext";

function LicensesDetailsUserView() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    const [curOrganization, errCheck] = props.getOrganizationWithChecks();
    if (errCheck != null) {
        return errCheck;
    }
    
    let allLicenses: LicenseCode[]|undefined = (curOrganization == undefined || curOrganization!.licenses == null) ? undefined : curOrganization!.licenses;

    return <LicensesDetails aseclaAdminView={false} allLicenses={allLicenses} curOrganizationId={curOrganization!.id}></LicensesDetails>
}
export default LicensesDetailsUserView
