import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { ContextProps } from "../../../type/ContextProps";
import { GetPriceResponse } from "../../../type/responses/GetPriceResponse";
import { BonusCode } from "../../../type/BonusCode";
import { LicenseType } from "../../../type/LicenseType";
import { Trans, useTranslation } from "react-i18next";
import { usePrice } from "../../../hooks/mutations/usePrice";
import PriceCaption from "./PriceCaption";

export enum PriceLayout {
    DIV_WITH_DESCRIPTIN,
    DIV_PURE_PRICE,
    SPAN
}

interface PriceParams {
    quantity: number,
    length: number,
    licenseType: LicenseType,
    bonusCode?: BonusCode,
    bonusCodeValid?: boolean,
    showHint: boolean,
    showDiscount: boolean,
    includeOrganizationDiscount?: boolean,
    displayNetAndBrutto?: boolean,
    setPrice?: React.Dispatch<React.SetStateAction<GetPriceResponse | null>>
    priceLayout?: PriceLayout
}
export const VAT_VALUE = 0.23;
function Price({quantity, length, licenseType, bonusCode, bonusCodeValid, showDiscount, includeOrganizationDiscount = true, displayNetAndBrutto = false, setPrice, priceLayout = PriceLayout.SPAN}: PriceParams) {
    const {t} = useTranslation();

    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [ priceResponse, setPriceResponse] = React.useState<GetPriceResponse | null>(null);
    const { getPrice, priceError, priceSuccess } = usePrice(setPriceResponse, includeOrganizationDiscount);

    let priceToCurrencySpace : boolean = !showDiscount;

    React.useEffect(() => {
        if (setPrice !== undefined) {
            setPrice(priceResponse);
        }
    }, [priceResponse]);

    React.useEffect(() => {
        if (licenseType != null && quantity !== undefined) {
            getPrice({
                bonusCode: (bonusCode != undefined && bonusCodeValid) ? bonusCode.theCode : null,
                length: length,
                lengthUnit: licenseType.lengthUnit,
                licenseTypeId: licenseType.id!,
                numberOfUsers: quantity,
            });
        }
    }, [quantity, length, licenseType, bonusCodeValid, props.currentOrganizationNo]);
    //Don't call on bonusCode change to not make second call in parallel (with outdated value bonusCodeValid)

    if (!priceSuccess || priceResponse == null) {
        return <div className="spinner"></div>
    }
    if (priceError) {
        return <></>
    }

//     <Price length={length!} licenseType={licType!} quantity={quantity!} showHint={false} showDiscount={true}
//     bonusCode={bonusCode ?? undefined} bonusCodeValid={bonusCodeValid} displayNetAndBrutto={true}                        
// ></Price> netto (6023 brutto)

    let showFinalValue = !showDiscount || priceResponse.discountedValue === null || priceResponse.discountedValue === undefined || (priceResponse.baseValue === priceResponse.discountedValue);


    interface PriceWithDescriptionParams {
        price: number,
        description?: string
    }
    const PriceWithDescription = ({price}: PriceWithDescriptionParams) => {
        return <>
            <PriceCaption price={price} currency={priceResponse.currency} priceToCurrencySpace={priceToCurrencySpace}></PriceCaption>
            {displayNetAndBrutto && <> {"netto"}</>}
            {displayNetAndBrutto && <> ( 
                <PriceCaption price={price * (1 + VAT_VALUE)} currency={priceResponse.currency} priceToCurrencySpace={priceToCurrencySpace}></PriceCaption> brutto)
            </>}
        </>
    }

    const CrossOutIf = ({children, cross}: any) => {
        if (cross) return <s>{children}</s>
        return children
    }

    let discountToBeDisplayed: boolean = showDiscount && priceResponse.discountedValue !== null && priceResponse.discountedValue !== undefined && (priceResponse.baseValue !== priceResponse.discountedValue);

    switch (priceLayout) {
        case PriceLayout.DIV_WITH_DESCRIPTIN:
            return <>
                {priceResponse != null && <>
                    <div>
                        <CrossOutIf cross={discountToBeDisplayed}>
                            <Trans i18nKey="Price: _price_" 
                                components={{price: <PriceWithDescription price={priceResponse.baseValue}></PriceWithDescription>}}>
                            </Trans>
                        </CrossOutIf> 
                    </div>
                    {discountToBeDisplayed &&
                        <div>
                            <Trans i18nKey="Discounted price: _price_" components={{price: <PriceWithDescription price={priceResponse.discountedValue!}></PriceWithDescription>}}></Trans>
                        </div>
                    }
                </>}
            </>
        case PriceLayout.DIV_PURE_PRICE:
            return <>
            {priceResponse != null && <>
                {showFinalValue && 
                    <div className="costDiv">
                        <PriceWithDescription price={priceResponse.discountedValue ?? priceResponse.baseValue}></PriceWithDescription>
                    </div>
                }
                {discountToBeDisplayed &&
                    <div className="costDiv">
                        <div className={"baseValue"}>
                            <s>
                                <PriceWithDescription price={priceResponse.baseValue}></PriceWithDescription>
                            </s>
                        </div>
                        <PriceWithDescription price={priceResponse.discountedValue!}></PriceWithDescription>
                    </div>
                }
            </>}
        </>
        case PriceLayout.SPAN:
            return <>
                {priceResponse != null && <>
                    {showFinalValue && 
                        <span className="costDiv">
                            <PriceWithDescription price={priceResponse.discountedValue ?? priceResponse.baseValue}></PriceWithDescription>
                        </span>
                    }
                    {discountToBeDisplayed &&
                        <span className="costDiv">
                            <span className={"baseValue"}>
                                <s>
                                    <PriceWithDescription price={priceResponse.baseValue}></PriceWithDescription>
                                </s>
                            </span>
                            &nbsp;
                            <PriceWithDescription price={priceResponse.discountedValue!}></PriceWithDescription>
                        </span>
                    }
                </>}
            </>
    }

}
export default Price;
