import { AseclaStandardResponse } from "./AseclaStandardResponse";
import { OrganizationData, handleOrganizationDataResponse } from "./OrganizationData";

type BaseProps = AseclaStandardResponse;
interface OrganizationResponse extends BaseProps { 
    organization : OrganizationData
};
export default OrganizationResponse;

export const handleOrganizationResponse = (ret: OrganizationResponse): OrganizationResponse => {
	if (ret.organization) {
        handleOrganizationDataResponse(ret.organization);
	}
	return ret;
}