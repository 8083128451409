import React from "react";
import AseclaDataContext from "../store/AseclaDataContext";
import { ContextProps } from "../type/ContextProps";
import { useTranslation } from 'react-i18next';
import CreateOrganizationRequest from "../type/request/CreateOrganizationRequest";
import AseclaButton from "./utils/AseclaButton";
import InvoiceDataForm from "./InvoiceDataForm";
import { useCreateOrganization } from "../hooks/mutations/useOrganizationOperations";
import styled from "styled-components";

interface CreateOrganizationParams {
    onOrganizationCreated?: () => void
    cancelCreation?: () => void
}
function CreateOrganization({onOrganizationCreated, cancelCreation}: CreateOrganizationParams) {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [organizationName, setOrganizationName] = React.useState<string>("");
    const [companyName, setCompanyName] = React.useState<string>("");
    const [taxId, setTaxId] = React.useState<string>("");
    const [address, setAddress] = React.useState<string>("");
    const [city, setCity] = React.useState<string>("");
    const [zipCode, setZipCode] = React.useState<string>("");
    const [country, setCountry] = React.useState<string>("");
    const {t} = useTranslation();
    const [showOrgNameRestr, setShowOrgNameRestr] = React.useState<boolean>(false);
    const { createOrganization, validationError } = useCreateOrganization(onOrganizationCreated);

    const createOrganizationClick = () => {
        if (organizationName.length < 3 || organizationName.length > 60 || organizationName.includes("#")) {
            setShowOrgNameRestr(true);
            return;
        } else {
            setShowOrgNameRestr(false);
        }
        let requestData: CreateOrganizationRequest = {
            organizationName: organizationName,
            invoiceData: {
                companyName: companyName,
                taxId: taxId,
                address: address,
                city: city,
                zipCode: zipCode,
                country: country,
            }
        };
        createOrganization(requestData);
    }

    return <div>
        <div>
            <label htmlFor="organizationNameNew">{t("Organization name") as string} </label>
            <input name="organizationNameNew" id="organizationNameNew" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} disabled={props.freezeHeaderCounter > 0}/>
            {showOrgNameRestr && <div test-id="organizationNameConstraint">{t("Organization name should contain 3 to 60 charactes. Do not use $ sign") as string}</div>}
        </div>
        <div>{t("Invoice data") as string}:</div>
        <InvoiceDataForm
            taxId={taxId} setTaxId={setTaxId}
            companyName={companyName} setCompanyName={setCompanyName}
            address={address} setAddress={setAddress}
            city={city} setCity={setCity}
            zipCode={zipCode} setZipCode={setZipCode}
            country={country} setCountry={setCountry}
            validationError={validationError}
        ></InvoiceDataForm>

        <OrganizationDiv>
            {cancelCreation !== undefined && <AseclaButton action={(e) => cancelCreation()}>{t("Cancel organization creation") as string}</AseclaButton>}
            <AseclaButton action={(e) => createOrganizationClick()}>{t("Create Organization") as string}</AseclaButton>
        </OrganizationDiv>
    </div>
}
export default CreateOrganization;

let OrganizationDiv = styled.div`
    margin-top: 16px;
`