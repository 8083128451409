import styled from "styled-components";
import i18n from "../../../i18n";

interface PriceCaptionParams {
    price: number,
    currency: string
    priceToCurrencySpace?: boolean
}
function PriceCaption ({price, currency, priceToCurrencySpace = false}: PriceCaptionParams) {
    
    const formatNumber = (value: number): string => {
        let locale = i18n.language === "pl" ? 'pl-PL' : 'en-US';
        let decimals: number = 2;
        return new Intl.NumberFormat(locale, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
          useGrouping: true
        }).format(value);
    };

    return <PriceSpan>
        <span className={"finalValue"}>
            {formatNumber(price)}
        </span>
        {priceToCurrencySpace && " "}
        <span className={"currency"}>
            {currency}
        </span>
    </PriceSpan>
}
export default PriceCaption;

let PriceSpan = styled.span`
    gap: 0px;
`