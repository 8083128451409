import { User } from "../../type/User";

type ContactElementParams = {
    user: User,
}
function ContactElement({user} : ContactElementParams) {   
    if (!user.mail || user.mail === "") {
        return <>
            <div>{user.id}</div>
        </>
    }
    if (!user.id || user.id === "") {
        return <>
            <div>{user.mail}</div>
        </>
    }
    return <>
            <div>{user.mail}</div>
            <div className="hint no-break">{user.id}</div>
        </>
}

export default ContactElement;