import React from "react";
import { LicenseType } from "../../../type/LicenseType";
import { Product } from "../../../type/Product";
import { PropertyQuestion } from "../PropertyQuestion";
import { useNavigate, useSearchParams } from "react-router-dom";
import './AseclaOffer.css';
import AseclaOfferProductTable from "../AseclaOfferProductTable";

import { useLicenseTypesOffered } from "../../../hooks/useLicenseTypes";
import Loading from "../../utils/Loading";
import IsErrorMessage from "../../utils/IsErrorMessage";
import UserOrderDefine from "./UserOrderDefine";
import UserOrderVerifyAndConfirm from "./UserOrderVerifyAndConfirm";
import i18n from "../../../i18n";

let getNumberValue = (param: string | null, defaultValue: number| undefined): number| undefined => {
    if (param !== null) {
        const quantityVal = Number.parseInt(param);
        if (!Number.isNaN(quantityVal)) {
            return quantityVal;
        }
    }
    return defaultValue;
}

function AseclaOffer() {    
    const [searchParams, ] = useSearchParams();



    /**
     * When user selected license type he needs to provide some properties of it. Here are his answers
     */
    const [licTypePropValue, setLicTypePropValue] = React.useState<Array<PropertyQuestion>>([]);
    const [quantity, setQuantity] = React.useState<number|undefined>(getNumberValue(searchParams.get('quantity'), undefined));
    const [length, setLength] = React.useState<number>(3);
    const [onlyVisibleRow, setOnlyVisibleRow] = React.useState<number>(-1);
    const [propertiesAccepted, setPropertiesAccepted] = React.useState<boolean>(false);
    const { licenseTypes, isLoading, isError } = useLicenseTypesOffered();
    const navigate = useNavigate();



    React.useEffect(() => {
        if (licenseTypes) {
            // const quantityParam = searchParams.get('quantity');
            // if (quantityParam !== null) {
            //     const quantityVal = Number.parseInt(quantityParam);
            //     if (!Number.isNaN(quantityVal) && quantityVal !== quantity) {
            //         setQuantity(quantityVal);
            //     }
            // }
            
            const licenseTypeId = searchParams.get('licenseTypeId');
            if (licenseTypeId !== undefined && licenseTypeId !== null) {
                let licNo = Number(licenseTypeId);
                if (licNo !== onlyVisibleRow) {
                    selectOrderType(licNo);
                }
            } else {
                const licenseTechnicalName = searchParams.get('tname');
                if (licenseTechnicalName !== undefined && licenseTechnicalName !== null) {
                    if (onlyVisibleRow === -1) {
                        selectOrderTypeByName(licenseTechnicalName);
                    }
                }
            }
        }
    }, [licenseTypes, onlyVisibleRow])

    React.useEffect(() => {
    }, [quantity]);

    const preparePropertiesToFill = (licType: LicenseType) => {
        let unAnsweared: PropertyQuestion[] = [];
        let answeared: any[] = Object.keys(licType.properties);
        let product: Product = licType.product;
        for (let i = 0; i < product.properties.length; i++) {
            let propId = product.properties[i].propertyId;
            if (answeared.indexOf(propId + "") === -1) {
                unAnsweared.push({
                    productPropertyIndex: i,
                    propertyId: product.properties[i].propertyId,
                    value: ""
                });
            }
        }
        setLicTypePropValue(unAnsweared);
    }

    const setVerifiedQuantityAndLength = (licType: LicenseType, quantityArg: number, lengthArg: number) => {
        let quantity: number = quantityArg;
        let length: number = lengthArg;
        if (licType.minLength !== 0 && length < licType.minLength) {
            length = licType.minLength;
        }
        if (licType.maxLength !== 0 && length > licType.maxLength) {
            length = licType.maxLength;
        }
        setLength(length);

        if (licType.minQuantity !== 0 && (quantity ?? 0) < licType.minQuantity) {
            quantity = licType.minQuantity;
        }
        if (licType.maxQuantity !== 0 && (quantity ?? 0) > licType.maxQuantity) {
            quantity = licType.maxQuantity;
        }
        setQuantity(quantity);
    }
    const selectOrderType = (licenseTypeNo : number): void => {
        if (licenseTypeNo !== onlyVisibleRow) {
            if (licenseTypeNo !== -1) {
                setOnlyVisibleRow(licenseTypeNo);
                if (licenseTypes !== undefined && licenseTypes != null) {
                    let licType: LicenseType = licenseTypes[licenseTypeNo];
                    setVerifiedQuantityAndLength(licType, quantity ?? licType.defaultQuantity, licType.defaultLength);
                    preparePropertiesToFill(licType);
                }
            } else {
                setOnlyVisibleRow(-1);
                setPropertiesAccepted(false);
            }
        }
    }
    const selectOrderTypeByName = (technicalName : string): void => {
        if (licenseTypes != null) {
            for (let i = 0; i < licenseTypes.length; i++) {
                if (licenseTypes[i].technicalName.toLowerCase() === technicalName.toLowerCase()) {
                    selectOrderType(i);
                    return;
                }
            }
        }
        selectOrderType(-1);
    }
    const selectOrderTypeById = (licenseTypeId : number): void => {
        if (licenseTypes != null) {
            for (let i = 0; i < licenseTypes.length; i++) {
                if (licenseTypes[i].id === licenseTypeId) {
                    selectOrderType(i);
                    return;
                }
            }
        }
        selectOrderType(-1);
    }

    const onUserOrderSelectByName = (name: string) => {
        if (licenseTypes != null) {
            for (let i = 0; i < licenseTypes.length; i++) {
                let r: LicenseType = licenseTypes[i];
                if (r.includeInOffer && r.names[i18n.language] === name) {
                    setOnlyVisibleRow(i);
                    let licType: LicenseType = licenseTypes[i];
                    if (onlyVisibleRow === -1) {
                        navigate("/aseclaOfferPage?tname=" + licType.technicalName);
                    }
                }
            }
        }
    }

    if (isLoading) {
        return <Loading></Loading>
    }

    if (isError) {
        return <IsErrorMessage></IsErrorMessage>
    }

    //1. Choose product from list
    if (onlyVisibleRow === -1) {
        return <AseclaOfferProductTable onUserOrderSelectByName={onUserOrderSelectByName}></AseclaOfferProductTable>
    //2. Customize product
    } else if (!propertiesAccepted) {
        let licType: LicenseType = licenseTypes[onlyVisibleRow];

        return <><UserOrderDefine
            licType={licType}
            switchSelectedLicenseTypeTo={selectOrderTypeById}
            length={length} setLength={setLength}
            quantity={quantity} setQuantity={setQuantity}
            licTypePropValue={licTypePropValue} setLicTypePropValue={setLicTypePropValue}
            setPropertiesAccepted={setPropertiesAccepted}
            allAvailableLicenses={licenseTypes??[]}
        ></UserOrderDefine>
        {/* <AseclaOfferCustomizeProduct
            licType={licType}
            switchSelectedLicenseTypeTo={selectOrderTypeById}
            length={length} setLength={setLength}
            quantity={quantity} setQuantity={setQuantity}
            licTypePropValue={licTypePropValue} setLicTypePropValue={setLicTypePropValue}
            setPropertiesAccepted={setPropertiesAccepted}
            allAvailableLicenses={licenseTypes??[]}
        ></AseclaOfferCustomizeProduct> */}
        </>
    //3. Order summary, Agreements
    } else {
        let licType = licenseTypes[onlyVisibleRow];
        return <UserOrderVerifyAndConfirm
            licType={licType}
            lengthUnit={licType.lengthUnit}
            licTypePropValue={licTypePropValue}
            quantity={quantity}
            length={length}
            backToOrderDefinition={() => {setPropertiesAccepted(false);}}
        ></UserOrderVerifyAndConfirm>
    }

}
export default AseclaOffer;