import InvoiceData from "../InvoiceData";
import { LicenseCode } from "../LicenseCode";
import { handleLicenseCodes } from "./GetAllLicenseCodesResponse";

export type OrganizationData = {
	id: number,
	name: string,
	haveManagerRights: boolean,
	haveAdminRights: boolean,
	/**
	 * Users are sent only for user with admin rights 
	 */
	users: string[],
	/**
	 * Admins are sent only for user with admin rights 
	 */
	admins: string[],
	/**
	 * Managers are sent only for user with manager rights 
	 */
	managers: string[],
	/**
	 * Licenses are sent only for user with manager rights 
	 */
	licenses: LicenseCode[],
	/**
	 * Discount is sent only for user with manager rights 
	 */
	discount: number,

	invoiceData: InvoiceData,
}

export const handleOrganizationDataResponse = (organization: OrganizationData): OrganizationData => {
	if (organization.licenses != null) {
        handleLicenseCodes(organization.licenses);
	}
	return organization;
}