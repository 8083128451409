
import { ContextProps } from "../../type/ContextProps";
import { getCollectedLicenses, LicenseStatistics } from "../../type/LicenseStatistics";
import { useTranslation } from 'react-i18next';
import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { DataSource } from "../utils/bigTable/definition/DataSource";
import { HeaderDefinition } from "../utils/bigTable/definition/HeaderDefinition";
import ConstantValuesBigTableFactory from "../utils/bigTable/ConstantValuesBigTableFactory";
import { CellProperty } from "../utils/bigTable/definition/HeaderDefinition";
import ManageUsersPage from "../../page/ManageUsers";
import { useNavigate } from "react-router-dom";
import TitleBanner from "../utils/TitleBanner";
import LicensesDetailsPage from "../../page/LicensesDetailsPage";
import YouHaveLicenseToActivate from "../home/YouHaveLicenseToActivate";

const predefineHeaders = (): HeaderDefinition[] => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    let headers: HeaderDefinition[] = [];

    const defineTableHeader = (props: HeaderDefinition) => {
        headers.push(props);
    }

    const get = (getValue: (licStat: LicenseStatistics) => any): (cellProperty: CellProperty, dataSource: DataSource) => any => {
        return (cellProperty: CellProperty, dataSource: DataSource) => getValue(cellProperty.object);
    }

    const getAssigned = (licStat: LicenseStatistics): any => {
        return <a onClick={e => navigate("/" + ManageUsersPage.CONTEXT_PATH)}>{licStat.usrs.length}</a>;
    }
    const getAvailable = (licStat: LicenseStatistics): any => {
        let active = 0;
        let notActivatetYet = 0;
        for (let i = 0; i < licStat.codes.length; i++) {
            let code = licStat.codes[i];
            if (code.activeNow) {
                active += code.usersNo;
            }
            if (code.licenseStart == null) {
                notActivatetYet += code.usersNo;
            }
        }
        return <a onClick={e => navigate("/" + ManageUsersPage.CONTEXT_PATH)}>{active + ((notActivatetYet > 0) ? "(+" + notActivatetYet + " " + t("in non-activated licenses") + ")" : "")}</a>;
    }

    defineTableHeader({headerKey: "PRODUCT_NAME", display: true, displayedHeader: t("Product"), getCellContent: get((stats) => stats.licType.product?.names["en"])});
    defineTableHeader({headerKey: "LICENSE_NAME", display: true, displayedHeader: t("License"), getCellContent: get((stats) => stats.licType.names["en"])});
    defineTableHeader({headerKey: "ASSIGNED", display: true, displayedHeader: t("Assigned"), getCellContent: get(getAssigned)});
    defineTableHeader({headerKey: "AVAILABLE", display: true, displayedHeader: t("Available"), getCellContent: get(getAvailable)});

    return headers;
}

function LicensesList() {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [allHeaders, ] = React.useState<HeaderDefinition[]>(predefineHeaders());
    const navigate = useNavigate();
    const {t} = useTranslation();


    const [curOrganization, errCheck] = props.getOrganizationWithChecks();
    if (errCheck != null) {
        return errCheck;
    }
    let collectedLicenses: Map<number, LicenseStatistics> = getCollectedLicenses(curOrganization!.licenses, false);

    let createNewTable = (licStats: LicenseStatistics[]) => {
        let data: DataSource = {
            onCellClick: (_, header) => {
                navigate("/" + LicensesDetailsPage.CONTEXT_PATH);
            },
            getCellClassname(_, header) {
                return "clickable";
            }
        }

        return <>
            <ConstantValuesBigTableFactory
                dataSource={data}
                allHeaders={allHeaders}
                objects={licStats}
                initPageSize={100}
                showPagination={false}
                showPageSize={false}
            ></ConstantValuesBigTableFactory>
        </>
    }

    return <>
        <TitleBanner title={t("Licenses")} organization={true}></TitleBanner>
        <YouHaveLicenseToActivate></YouHaveLicenseToActivate>
        {createNewTable(Array.from(collectedLicenses.values()))}
    </>
}
export default LicensesList

