import React from "react";
import AseclaDataContext from "../../store/AseclaDataContext";
import { ContextProps } from "../../type/ContextProps";
import SaveBonusCodeResponse from "../../type/responses/SaveBonusCodeResponse";
import { requestServerPOST } from "../../store/ServerConnection";
import { useMutation } from "@tanstack/react-query";
import { Notifications } from "../../integration/Notifications";
import { BonusCode } from "../../type/BonusCode";
import { t } from "i18next";


export function useSaveBonusCode(bonusCode: BonusCode, bonusCodeSaved: (bonusCode: BonusCode) => void) {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    
    const { mutate: saveBonusCode } = useMutation({
        mutationFn: async () => {
            if (!props.authentication.initialized() || !props.authentication.isAuthenticated()) return null
            let body: any = {bonusCode};
            return requestServerPOST("saveBonusCode", body, props);
        },
        onSuccess: (resp: SaveBonusCodeResponse|null) => {
            if (resp != null) {
                serverSuccess(t("Bonus code saved"));
                bonusCodeSaved(resp.bonusCode);
            }
        },
        onError: (error: any, variables: any) => {
            console.log(error);
            console.log(variables);
            serverError(t("Error while saving bonus code"))
        }
    });
  
    return { saveBonusCode };
}

export function useDeleteBonusCode(bonusCodeDeleted: () => void) {
    const { serverError, serverSuccess } = Notifications();
    const props = React.useContext(AseclaDataContext) as ContextProps;
    
    const { mutate: deleteBonusCode } = useMutation({
        mutationFn: async ({bonusCodeId}) => {
            if (!props.authentication.initialized() || !props.authentication.isAuthenticated()) return null
            if (bonusCodeId == -1) {
                bonusCodeDeleted();
                return () => {}
            }
            let body: any = {bonusCodeId};
            return requestServerPOST("deleteBonusCode", body, props);
        },
        onSuccess: (resp: any) => {
            if (resp != null) {
                bonusCodeDeleted();
            }
            serverSuccess(t("Bonus code deleted"));
        },
        onError: (error: any, variables: any) => {
            console.log(error);
            console.log(variables);
            serverError(t("Error while deleting bonus code"))
        }
    });
  
    return { deleteBonusCode };
}
