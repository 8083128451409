import { useQuery } from "@tanstack/react-query";
import { OrganizationData } from "../../type/responses/OrganizationData";
import { getUrl, prepareHeadersPromise } from "../../store/ServerConnection";
import getAuthentication from "../../store/AuthenticationPageBase";
import ListOrganizationsResponse, { handleListOrganizationsResponse } from "../../type/responses/ListOrganizationsResponse";
import { queryClient } from "../../App";
import GetOrganizationsResponse from "../../type/responses/GetOrganizationsResponse";

export const TANSTACK_ORGANIZATIONS_ADMIN_KEY = ["ORGANIZATIONS", "ADMIN"];
interface useOrganizationsResult {
    organizations: OrganizationData[],
    count: number
    isFetching: boolean,
    isError: boolean,
    onOrganizationChanged: (ret: GetOrganizationsResponse, curOrganizationId: number, filter: string) => void,
}
export function useAdminOrganizations(filter: string): useOrganizationsResult {
    const authentication = getAuthentication();

    let query = useQuery<ListOrganizationsResponse>({
        queryKey: ["ORGANIZATIONS", "ADMIN", filter],
        staleTime: Infinity,
        cacheTime: Infinity,
        queryFn: async () => {
            if (!authentication.initialized() || !authentication.isAuthenticated()) return null;
            const requestOptions = {
                method: 'POST',
                headers: await prepareHeadersPromise(null, authentication),
                body: JSON.stringify({
                    filter: filter,
                    page: 0,
                    size: 1000
                }),
            };
            const response = await fetch(getUrl("listOrganizations"), requestOptions);
            if (response.status != 200) {
                return Promise.reject(new Error());
            }
            return response.json().then(r => handleListOrganizationsResponse(r) as any);
        }
    });

    const onOrganizationChanged = (ret: GetOrganizationsResponse, curOrganizationId: number, filter: string) => {        
        let organizations = query.data?.organizations ?? [];
        let retOrganizationNo = -1;
        for (let i = 0; i < ret.organizations.length; i++) {
            if (ret.organizations[i].id === curOrganizationId) {
                retOrganizationNo = i;
            }
        }
        if (retOrganizationNo === -1) {
            return;
        }
        let orgs = [...organizations];
        for (let i = 0; i < orgs.length; i++) {
            if (orgs[i].id == curOrganizationId) {
                orgs[i] = {...ret.organizations[retOrganizationNo]};
            }
        }
        let newData : ListOrganizationsResponse = {
            organizations: orgs,
            count: orgs.length,
            success: true,
        }
        queryClient.invalidateQueries(TANSTACK_ORGANIZATIONS_ADMIN_KEY);
        queryClient.setQueryData(["ORGANIZATIONS", "ADMIN", filter], newData)
    }

    return {
        organizations: query.data?.organizations ?? [],
        count: query.data?.count ?? 0,
        isError: query.isError,
        isFetching: query.isFetching,
        onOrganizationChanged: onOrganizationChanged,
    }
}
