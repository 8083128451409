import { LicenseCode } from "../LicenseCode";
import { AseclaStandardResponse } from "./AseclaStandardResponse";

type BaseProps = AseclaStandardResponse;
interface GetAllLicenseCodesResponse extends BaseProps { 
    licenseCodes : LicenseCode[],
};
export default GetAllLicenseCodesResponse;


export const handleLicenseCodes = (licenseCodes: LicenseCode[]): LicenseCode[] => {
    for (let licNo = 0; licNo < licenseCodes.length; licNo++) {
        let licenseCode: LicenseCode = licenseCodes[licNo];
        if (licenseCode.canClaimUntil) licenseCode.canClaimUntil = new Date(licenseCode.canClaimUntil);
        if (licenseCode.licenseStart) licenseCode.licenseStart = new Date(licenseCode.licenseStart);
        if (licenseCode.licenseEnd) licenseCode.licenseEnd = new Date(licenseCode.licenseEnd);
    }
    return licenseCodes;
}