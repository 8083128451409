import styled from "styled-components";
import Price from "../pricing/Price";
import AseclaButton from "../../utils/AseclaButton";
import { LicenseType } from "../../../type/LicenseType";
import { t } from "i18next";
import { ContextProps } from "../../../type/ContextProps";
import React from "react";
import AseclaDataContext from "../../../store/AseclaDataContext";
import { Trans } from "react-i18next";
import UnitDiv from "../../utils/UnitDiv";

type UserOrderMainTableParams = {
    licType: LicenseType,
    quantity: number|undefined,
    accept: (noOfYears: number) => (e:  React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    setQuantity: (quantity: number) => void
}
function UserOrderMainTable({licType, quantity, accept, setQuantity}: UserOrderMainTableParams) {
    return <OfferTable>
        <thead>
            <tr><th>{t("Number of Licenses") as string}</th><th>{t("Interval") as string}</th><th>{t("Gross value for protection") as string}</th></tr>
        </thead>
        <tbody>
            {licType.technicalName !== "Hello" && <>
                <tr>
                    <QuantityColumn licType={licType} quantity={quantity} setQuantity={setQuantity}></QuantityColumn>
                    <OtherColumns quantity={quantity} length={1} licType={licType} accept={accept}></OtherColumns>
                </tr>
                <tr>
                    <OtherColumns quantity={quantity} length={2} licType={licType} accept={accept}></OtherColumns>
                </tr>
                <tr>
                    <OtherColumns quantity={quantity} length={3} licType={licType} accept={accept}></OtherColumns>
                </tr>
            </>}
            {licType.technicalName === "Hello" && <>
                <tr>
                    <QuantityColumn licType={licType} quantity={quantity} setQuantity={setQuantity}></QuantityColumn>
                    <OtherColumns quantity={quantity} length={7} licType={licType} accept={accept}></OtherColumns>
                </tr>
            </>}
        </tbody>
    </OfferTable>
}
export default UserOrderMainTable;

const QuantityColumn = ({licType, quantity, setQuantity}: {licType: LicenseType, quantity: number|undefined, setQuantity: (quantity: number)=>void}) => {
    return <td rowSpan={3}>
        <input
            max={licType.maxQuantity === 0 ? undefined : licType.maxQuantity}
            value={quantity}
            min={licType.minQuantity === 0 ? 1 : licType.minQuantity}
            onChange={e => {if (!isNaN(Number(e.target.value))) setQuantity(Number(e.target.value))}}
            id="quantity"
            name="quantity"
            type="number"
            size={5}
            />
    </td>
}

const OtherColumns = ({quantity, length, licType, accept}: 
        {quantity: number|undefined, length:number, licType:LicenseType, accept: ((noOfYears: number) => (e:  React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)}) => {
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;

    let canSelect: boolean = props.getCurrentOrganization() == null || (props.getCurrentOrganization()?.haveManagerRights ?? false);
    if (quantity === undefined 
            || quantity <= 0
            || licType.minQuantity !== 0 && licType.minQuantity > quantity
            || licType.maxQuantity !== 0 && licType.maxQuantity < quantity) {
        canSelect = false;
    }
    const unit = licType.lengthUnit;

    return <>
        <td><Trans i18nKey="protection_length" components={{ unit: <UnitDiv no={length} lengthUnit={unit} lowercase={false}></UnitDiv>}} values={{ length: length}}></Trans></td>
        <td><Price length={length} licenseType={licType!} quantity={quantity!} showDiscount={true} showHint={false}></Price></td>
        <td><AseclaButton className="small" action={accept(length)} enabled={canSelect}>{t("Select") as string}</AseclaButton></td>
    </>
}

const OfferTable = styled.table`
    width: calc(100% - 20px);
    text-align: center;
`;

