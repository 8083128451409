import React from 'react';
import { UserOrder } from '../../type/UserOrder';
import { ContextProps } from '../../type/ContextProps';
import AseclaDataContext from '../../store/AseclaDataContext';
import AseclaButton from '../utils/AseclaButton';
import InvoiceDataForm from '../InvoiceDataForm';
import InvoiceData from '../../type/InvoiceData';
import { useTranslation } from 'react-i18next';
import { useOrganizations } from '../../hooks/useOrganizations';
import { useSaveInvoiceData } from '../../hooks/mutations/useInvoiceDataMutations';
import TitleBanner from '../utils/TitleBanner';


type OrdersInvoiceDataParams = {
    userOrder: UserOrder,
    cancelUserOrder: () => void,
}
function OrdersInvoiceData(params: OrdersInvoiceDataParams) {
    const {t} = useTranslation();
    const props: ContextProps = React.useContext(AseclaDataContext) as ContextProps;
    const [companyName, setCompanyName] = React.useState<string>("");
    const [taxId, setTaxId] = React.useState<string>("");
    const [address, setAddress] = React.useState<string>("");
    const [city, setCity] = React.useState<string>("");
    const [zipCode, setZipCode] = React.useState<string>("");
    const [country, setCountry] = React.useState<string>("");
    const [userOrder, setUserOrder] = React.useState<UserOrder>(params.userOrder);

    const { saveInvoiceData, validationError } = useSaveInvoiceData(setUserOrder, params.cancelUserOrder);

    const { isAseclaAdmin } = useOrganizations();

    React.useEffect(() => {
        let invoiceData: InvoiceData = userOrder.invoiceData;
        setCity(invoiceData.city);
        setCompanyName(invoiceData.companyName);
        setTaxId(invoiceData.taxId);
        setAddress(invoiceData.address);
        setZipCode(invoiceData.zipCode);
        setCountry(invoiceData.country);
    }, [userOrder]);
    
    

    return <div>
        <div><AseclaButton action={e => params.cancelUserOrder()}>{t("Back to order list") as string}</AseclaButton></div>
        {!isAseclaAdmin && <div>
            <div>{t("Company name") as string}: {userOrder.invoiceData.companyName}</div>
            <div>{t("Tax ID") as string}: {userOrder.invoiceData.taxId}</div>
            <div>{t("Address") as string}: {userOrder.invoiceData.address} {userOrder.invoiceData.zipCode} {userOrder.invoiceData.city} {userOrder.invoiceData.country}</div>
        </div>}
        {isAseclaAdmin && <div>
            <InvoiceDataForm
                taxId={taxId ?? ""} setTaxId={setTaxId}
                companyName={companyName ?? ""} setCompanyName={setCompanyName}
                address={address ?? ""} setAddress={setAddress}
                city={city ?? ""} setCity={setCity}
                zipCode={zipCode ?? ""} setZipCode={setZipCode}
                country={country ?? ""} setCountry={setCountry}
                validationError={validationError}
            >
                <label htmlFor={"TheOrganizationName"}>{t("Organization") as string}</label>
                <input name={"TheOrganizationName"} id={"TheOrganizationName"} value={userOrder.organization === null ? "" : userOrder.organization.name??""} disabled={true}/>
            </InvoiceDataForm>
            <AseclaButton action={e => saveInvoiceData({
                    userOrderId: userOrder.orderId,
                    invoiceData: {
                        companyName: companyName,
                        taxId: taxId,
                        address: address,
                        city: city,
                        zipCode: zipCode,
                        country: country
                    },
                    generateProForma: false
                })}>{t("Save invoice data") as string}</AseclaButton>
            <AseclaButton action={e => saveInvoiceData({
                    userOrderId: userOrder.orderId,
                    invoiceData: {
                        companyName: companyName,
                        taxId: taxId,
                        address: address,
                        city: city,
                        zipCode: zipCode,
                        country: country
                    },
                    generateProForma: true
                })}>{t("Save invoice data and generate Pro-forma") as string}</AseclaButton>
        </div>}
    </div>
}

export default OrdersInvoiceData;